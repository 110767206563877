<template>
  <div class="synthesis-projects">
    <div class="container is-fluid">
      <page-header>
        <h1 class="title">Projets</h1>
        <span slot="actions">
          <b-select placeholder="Période" v-model="currentMonth">
            <option
              v-for="date in months"
              :value="date.value"
              :key="date.value"
            >
              {{ date.name }}
            </option>
          </b-select>
        </span>
      </page-header>
      <div class="time">
        Dernière mise à jour le {{ latestRefresh | date("DD/MM/YYYY") }} à
        {{ latestRefresh | date("HH:mm") }}
      </div>
      <datatable
        :fetch="$Api.Sheet.fetchSheetsSynthesis"
        ref="projectList"
        entity="sheet"
        enableDownload
        downloadEntity="sheets/synthesis"
        :filters="{
          period: this.currentMonth,
        }"
      >
        <div
          slot="direction"
          slot-scope="{ item }"
          title="Direction"
          :sortable="true"
          sortBy="sheet.project.region.direction.name"
        >
          {{ item.direction }}
        </div>
        <div
          slot="region"
          slot-scope="{ item }"
          title="Région"
          :sortable="true"
          sortBy="sheet.project.region.name"
        >
          {{ item.region }}
        </div>
        <div
          slot="project"
          slot-scope="{ item }"
          title="Projet"
          :sortable="true"
          sortBy="sheet.project.name"
        >
          {{ item.projectName }}
        </div>
        <div
          slot="sheet"
          slot-scope="{ item: { sheet } }"
          title="Fiche Affaire"
          :sortable="true"
          sortBy="sheet.name"
        >
          {{ sheet && sheet.name }}
        </div>
        <div
          slot="owner"
          slot-scope="{ item }"
          title="Responsable"
          :sortable="true"
        >
          {{ item.ownerName }}
        </div>
        <div
          slot="milestone"
          slot-scope="{ item: { sheet } }"
          title="Jalon"
          :sortable="true"
          sortBy="sheet.name"
        >
          {{ sheet && sheet.milestone }}
        </div>
        <div slot="imputations" slot-scope="{ item }" title="Imputations">
          <span
            v-for="imputation in item.imputations"
            :key="'i_' + imputation.eotp"
            class="tag is-info is-light"
            >{{ imputation.eotp }}</span
          >
        </div>
        <div
          slot="oscarSubProjects"
          slot-scope="{ item }"
          title="Sous-projets Oscar"
        >
          <span
            v-for="osp in item.oscarSubProjects"
            :key="'o_' + osp.id"
            class="tag is-info is-light"
            >{{ osp.oscarSubProjectName }}</span
          >
        </div>
        <div
          slot="sheetStatus"
          slot-scope="{ item }"
          title="Statut"
          :sortable="true"
          sortBy="sheet.sheetStatus.name"
        >
          {{ item.sheetStatusName }}
        </div>
        <div
          slot="administrationStatus"
          slot-scope="{ item }"
          title="Statut de gestion"
          :sortable="true"
          sortBy="sheet.administrationStatus.name"
        >
          {{ item.administrationStatusName }}
        </div>
        <div
          slot="startDate"
          slot-scope="{ item: { sheet } }"
          title="Date début"
          :sortable="true"
          sortBy="sheet.startDate"
          type="date"
        >
          {{ sheet && sheet.startDate | date }}
        </div>
        <div
          slot="endDate"
          slot-scope="{ item: { sheet } }"
          title="Date fin"
          :sortable="true"
          sortBy="sheet.endDate"
          type="date"
        >
          {{ sheet && sheet.endDate | date }}
        </div>

        <div
          slot="referenceBudgetAmount"
          slot-scope="{ item }"
          title="Budget référence"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.referenceBudgetAmount | number }}
        </div>
        <div
          slot="amountAps"
          slot-scope="{ item }"
          title="Budget APS"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.amountAps | number }}
        </div>
        <div
          slot="amountExe"
          slot-scope="{ item }"
          title="Budget EXE"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.amountExe | number }}
        </div>
        <div
          slot="totalPhaseExpenses"
          slot-scope="{ item }"
          title="Dépenses au stade"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.totalPhaseExpenses | number }}
        </div>
        <div
          slot="totalVariation"
          slot-scope="{ item }"
          title="Ecarts cumulés"
          class="has-text-right"
          nofilter
        >
          {{ item.totalVariation | number }}
        </div>
        <div
          slot="totalMonthExpenses"
          slot-scope="{ item }"
          title="Dépenses du mois"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.totalMonthExpenses | number }}
        </div>
        <div
          slot="totalMonthVariation"
          slot-scope="{ item }"
          title="Ecart du mois"
          class="has-text-right"
          nofilter
        >
          {{ item.monthVariation | number }}
        </div>
        <div
          slot="isfOrdered"
          slot-scope="{ item }"
          title="ISF commandé"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.isfOrdered | number }}
        </div>
        <div
          slot="isfDone"
          slot-scope="{ item }"
          title="ISF facturé"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.isfDone | number }}
        </div>
        <div
          slot="rad"
          slot-scope="{ item }"
          title="RAD"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.rad | number }}
        </div>
        <div
          slot="capFnp"
          slot-scope="{ item }"
          title="CAP/FNP"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.capFnp | number }}
        </div>
        <div
          slot="currentTotalAmount"
          slot-scope="{ item }"
          :title="currentTotalAmountTitle"
          class="has-text-right"
          nofilter
        >
          {{ item.currentTotalAmount | number }}
        </div>
        <div
          slot="totalAmount"
          slot-scope="{ item }"
          title="Dépenses FDC"
          class="has-text-right"
          nofilter
        >
          {{ item.totalAmount | number }}
        </div>
        <div
          slot="previousAmount"
          slot-scope="{ item }"
          title="FDC VS M-1"
          class="has-text-right"
          nofilter
        >
          {{ (item.totalAmount - item.previousAmount) | number }}
        </div>
        <div
          slot="financialProgress"
          slot-scope="{ item }"
          title="Avancement financier"
          class="has-text-centered"
          nofilter
        >
          {{ item.financialProgress | percentage(2) }}
        </div>
        <div
          slot="realProgress"
          slot-scope="{ item }"
          title="Avancement physique"
          class="has-text-centered"
          nofilter
        >
          {{ item.progress | percentage(2) }}
        </div>
        <div
          slot="realProgressVariation"
          slot-scope="{ item }"
          title="Avancement du mois"
          class="has-text-centered"
          nofilter
        >
          {{ item.realProgressVariation | percentage(2) }}
        </div>
        <div
          slot="referenceBudgetIncomes"
          slot-scope="{ item }"
          title="Recettes budget référence"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.referenceBudgetIncomes | number }}
        </div>
        <div
          slot="incomesAps"
          slot-scope="{ item }"
          title="Recettes APS"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.incomesAps | number }}
        </div>
        <div
          slot="incomesExe"
          slot-scope="{ item }"
          title="Recettes EXE"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.incomesExe | number }}
        </div>
        <div
          slot="incomes"
          slot-scope="{ item }"
          title="Reprévisions FDC"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.incomes | number }}
        </div>
        <div
          slot="amountQuoted"
          slot-scope="{ item }"
          title="TS / Devis émis"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.amountQuoted | number }}
        </div>
        <div
          slot="amountOrdered"
          slot-scope="{ item }"
          title="BDC reçus"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.amountOrdered | number }}
        </div>
        <div
          slot="amountInvoiced"
          slot-scope="{ item }"
          title="Facturé"
          class="has-text-right"
          :sortable="true"
        >
          {{ item.amountInvoiced | number }}
        </div>
        <div
          slot="invoicedLeft"
          slot-scope="{ item }"
          title="Reste à facturer"
          class="has-text-right"
          nofilter
        >
          {{ (item.amountOrdered - item.amountInvoiced) | number }}
        </div>
        <div
          slot="progress"
          slot-scope="{ item }"
          title="Avancement"
          class="has-text-centered"
          nofilter
        >
          {{
            item.activity === 1
              ? item.progress
              : item.financialProgress | percentage(2)
          }}
        </div>
        <div
          slot="activity"
          slot-scope="{ item }"
          title="Activité déclarée"
          class="has-text-right"
          nofilter
        >
          {{ item.totalDeclaredActivity | number }}
        </div>
        <div
          slot="faePca"
          slot-scope="{ item }"
          title="FAE/PCA"
          class="has-text-right"
          nofilter
        >
          {{
            item.activity === 1
              ? item.progress * item.amountOrdered - item.amountInvoiced
              : (item.financialProgress * item.amountOrdered -
                  item.amountInvoiced)
                | number
          }}
        </div>
        <div
          slot="referenceBudgetMargin"
          slot-scope="{ item }"
          title="% MB budget de référence"
          class="has-text-centered"
          nofilter
        >
          {{ item.referenceBudgetMargin | percentage(2) }}
        </div>
        <div
          slot="apsMargin"
          slot-scope="{ item }"
          title="% MB budget APS"
          class="has-text-centered"
          nofilter
        >
          {{ item.apsMargin | percentage(2) }}
        </div>
        <div
          slot="exeMargin"
          slot-scope="{ item }"
          title="% MB budget EXE"
          class="has-text-centered"
          nofilter
        >
          {{ item.exeMargin | percentage(2) }}
        </div>
        <div
          slot="margin"
          slot-scope="{ item }"
          title="% MB"
          class="has-text-centered"
          nofilter
        >
          {{ item.margin | percentage(2) }}
        </div>
        <div
          slot="comment"
          slot-scope="{ item }"
          title="Commentaire"
          class="comment"
          :sortable="true"
        >
          {{ item.comment }}
        </div>
        <div
          slot="non-affecte-sap"
          slot-scope="{ item }"
          title="Non affecté SAP"
          nofilter
        >
          {{ item.unaffectedPhaseExpenses }}
        </div>
        <div
          slot="non-affecte-isf"
          slot-scope="{ item }"
          title="Non affecté ISF"
          nofilter
        >
          {{ (item.isfOrdered - item.affectedIsfOrdered) | number }}
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datatable from "../../components/elements/Datatable";
import moment from "moment";

export default {
  name: "synthesis-projects",
  components: {
    Datatable,
  },
  data() {
    return {
      currentMonth: "draft",
      months: [],
      project: {},
      regionColumns: {
        "direction.name": "Direction",
        name: "Région",
      },
      otpColumns: {
        otp: "Code SAP",
      },
    };
  },
  computed: {
    ...mapGetters({
      latestRefresh: "App/getLatestRefresh",
    }),
    currentTotalAmountTitle() {
      return `Dépenses FDC au ${this.$options.filters.date(
        this.latestRefresh,
        "DD/MM/YYYY"
      )} à ${this.$options.filters.date(this.latestRefresh, "HH:mm")}`;
    },
  },
  mounted() {
    this.$store.dispatch("App/loadLatestRefresh");

    this.$Api.Sheet.fetchPeriods().then((periods) => {
      this.months = [
        {
          value: "draft",
          name: "Aujourd'hui (non historisé)",
        },
      ];

      periods.forEach((p) => {
        const period = moment(p);
        const year = period.format("Y");
        const month = period.format("M");
        const months = this.$Api.Sheet.getMonths();
        let name = months[month] + " " + year;

        this.months.push({
          value: p,
          name,
        });
      });
    });
  },
  watch: {
    latestRefresh() {
      this.$refs.projectList.refresh();
    },
  },
  methods: {},
};
</script>

<style scoped>
.tag {
  margin-right: 3px;
  margin-bottom: 3px;
}
.time {
  margin-top: -24px;
}
.comment {
  white-space: nowrap;
}
</style>
